
import Vue from "vue";
import { GetReportingSumDataByLocationFidaV2LocationRep } from '@/api/fida';

import { mapState, mapMutations } from 'vuex';
import {
  format,
  addYears,
  addDays,
  startOfYear,
  startOfMonth,
  addMonths,
  endOfMonth
} from 'date-fns';
import { getStoreNameByCode } from '@/store/modules/exportFida';
import { addSign } from '@/util/replaceFidaKpi';

export default {
  data() {
    return {
      tableColumns: [
        {
          name: 'store',
          label: this.$store.state.user.translate.store,
          align: 'left'
        },
        {
          name: 'expected_traffic',
          label: this.$store.state.user.translate.required_traffic,
          align: 'right'
        },
        {
          name: 'compared_traffic',
          label: this.$store.state.user.translate.comparison_period_traffic,
          align: 'right'
        }
      ],
      selectedOption1: null,
      selectedOption2: null,
      comparedDate: [],
      dateFilter: {},
      textInput: '',
      loading: false,
      salesTarget: '',
      requiredTransactions: 0,
      conversionRate: 0,
      averagePurchase: 0,
      averagePurchaseFormated: 0,
      showCalculation: false,
      sumOfSalesTarget: 0,
      totalTraffic: 0,
      totalCompareTraffic: 0,
      no_history_data: true
    };
  },

  computed: {
    ...mapState('filter', ['filter']),
    formattedTotalTraffic() {
      return this.totalTraffic ? this.totalTraffic.toLocaleString() : '0';
    },
    formattedTotalCompareTraffic() {
      return this.totalCompareTraffic
        ? this.totalCompareTraffic.toLocaleString()
        : '0';
    },
    formattedSalesTarget: {
      get() {
        // Format sales target as a localized string
        return this.salesTarget ? Number(this.salesTarget).toLocaleString() : 0;
      },
      set(value) {
        // Remove non-numeric characters and set sales target
        const numericValue = value.replace(/[^0-9]/g, '');
        this.salesTarget = numericValue;
      }
    },
  },
  watch: {
    'filter.storeCodesCal': function(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getSalesTarget();
      }
    },
    dateFilter: {
      handler: 'getSalesTarget',
      deep: true
    }
  },

  async created() {
        this.averagePurchaseFormated = addSign('ap',0);
/*  this.setStartingPeriodCal({
      startingPeriodCal: this.formatDate2(startOfMonth(addMonths(new Date(), 1))
      )
    });
    this.setEndingPeriodCal({
      endingPeriodCal: this.formatDate2(endOfMonth(addMonths(new Date(), 1)))
    });
    this.setStartingComparedCal({
      startingComparedCal: this.formatDate2(startOfMonth(new Date()))
    });
    this.setEndingComparedCal({
      endingComparedCal: this.formatDate2(new Date())
    });
*/
    const dateFilter = {
      compareEndDate: this.filter.endingComparedCal,
      compareStartDate: this.filter.startingComparedCal,
      endDate: this.filter.endingPeriodCal,
      startDate: this.filter.startingPeriodCal
    };

    this.dateFilter = { ...dateFilter };
    this.getSalesTarget();
  },
  mounted() {
    Vue.prototype?.$mixpanel?.track('Traffic Calculator', {
      referrer: document.referrer
    });
    
  },
  methods: {
    ...mapMutations('filter', [
      'setCalendarDatesToStoreCal',
      'setStartingPeriodCal',
      'setEndingPeriodCal',
      'setStartingComparedCal',
      'setEndingComparedCal',
      'setCustomCalenderFlag'
    ]), // mapMutations
    formatDate2(date) {
      if (!date) return null;
      let newDate = format(date, 'yyyy-MM-dd');
      const [year, month, day] = newDate.split('-');
      return `${year}-${month}-${day}`;
    },

    calculateRequiredTraffic(kpis, newSalesTarget) {
      // Calculate the total current sales across all stores
      let totalCurrentSales = kpis.reduce((sum, store) => sum + store.sls, 0);

      let multiplier = newSalesTarget / totalCurrentSales;

      return kpis.map(store => {
        // Calculate the required traffic for this store to meet its new target
        let requiredTraffic = Math.ceil(store.trf * multiplier);

        return {
          storeCode: store._id + ' - ' + getStoreNameByCode(store._id),
          requiredTraffic: requiredTraffic.toLocaleString(),
          currentTraffic: store.trf.toLocaleString()
        };
      });
    },
    calculateTotalRequirements(kpis, newSalesTarget, totalStores) {
      let totalCurrentSales = kpis.reduce((sum, store) => sum + store.sls, 0);
      let totalCurrentTraffic = kpis.reduce((sum, store) => sum + store.trf, 0);

      let totalCurrentTransactions = kpis.reduce((sum, item) => sum + (item.trn || 0), 0);
      //   let totalCN = kpis.reduce((sum, store) => sum + store.cn, 0);

      let multiplier = newSalesTarget / totalCurrentSales;

      let totalRequiredTraffic = Math.ceil(totalCurrentTraffic * multiplier);
      let totalRequiredTransactions = Math.ceil(
        totalCurrentTransactions * multiplier
      );
      if(isNaN(totalRequiredTransactions))
      {
        totalRequiredTransactions= 1;
      }
      let conversionRate =
        (totalRequiredTransactions / totalRequiredTraffic) * 100;

      return {
        totalRequiredTraffic: totalRequiredTraffic,
        totalRequiredTransactions: totalRequiredTransactions,
        conversionRate: conversionRate.toFixed(2),
        totalCurrentTraffic: totalCurrentTraffic
      };
    },
    async getSalesTarget() {
      this.conversionRate = 0;
      this.requiredTransactions = 0;
      this.totalTraffic = 0;
      this.totalCompareTraffic = 0;
      //individual
      this.comparedDate = [];

      const params = {
        ...this.dateFilter,
        storeCodes: this.filter.storeCodesCal ?? '',
        kpiTypes: 'st'.split(','),
        frequency: 'all'
      };

      const response = await GetReportingSumDataByLocationFidaV2LocationRep(
        params
      );

      this.sumOfSalesTarget = 0;
      if (response.kpis) {
        for (let i = 0; i < response.kpis.length; i++) {
          this.sumOfSalesTarget += response.kpis[i].st;
        }
      }
      this.salesTarget = this.sumOfSalesTarget

      var endingDate = addDays(new Date(), -366).toISOString();
      var startingDate = addDays(new Date(), -375).toISOString();
      const obj = {
        startDate: startingDate.split('T')[0],
        endDate: endingDate.split('T')[0],
        compareEndDate: endingDate.split('T')[0],
        compareStartDate: startingDate.split('T')[0],
        storeCodes: this.filter.storeCodesCal ?? '',
        kpiTypes: 'sls,trf'.split(','),
        frequency: 'all'
      };

      const response1Year = await GetReportingSumDataByLocationFidaV2LocationRep(
        obj
      );

      let sales = response1Year.kpis.reduce((sum, store) => sum + store.sls, 0);
      let traffic = response1Year.kpis.reduce(
        (sum, store) => sum + store.trf,
        0
      );
      if (sales > 0 && traffic > 0) {
        this.no_history_data = true;
      } else {
        this.no_history_data = false;
      }

      //end check for 1 year data
    },
    toggleCalculation() {
      this.showCalculation = !this.showCalculation;
    },
    setDateFilter(value) {
      this.dateFilter = value;
    },
    removeCommas(str) {
      return str.replace(/,/g, '');
    },
    async calculate() {
      this.loading = true;
      const params = {
        ...this.dateFilter,
        storeCodes: this.filter.storeCodesCal ?? '',
        kpiTypes: 'st,sls,trf,cn'.split(','),
        frequency: 'all'
      };
      let totalStores = this.filter.storeCodesCal.length;

      const response = await GetReportingSumDataByLocationFidaV2LocationRep(
        params
      );
      const requiredTraffic = this.calculateRequiredTraffic(
        response.kpisCompare,
        this.removeCommas(this.formattedSalesTarget)
      );
  
      const totalRequiredTraffic = this.calculateTotalRequirements(
        response.kpisCompare,
        this.removeCommas(this.formattedSalesTarget),
        totalStores
      );

      //Total
      //      this.conversionRate = totalRequiredTraffic.conversionRate.toLocaleString();
      this.conversionRate = isNaN(totalRequiredTraffic.conversionRate)
        ? 0
        : totalRequiredTraffic.conversionRate.toLocaleString();

      this.requiredTransactions = totalRequiredTraffic.totalRequiredTransactions.toLocaleString();
      this.totalTraffic = totalRequiredTraffic.totalRequiredTraffic;
      this.totalCompareTraffic = totalRequiredTraffic.totalCurrentTraffic;
      //individual
      this.comparedDate = requiredTraffic;
      this.averagePurchase = Math.round(Number(this.salesTarget/this.removeCommas(this.requiredTransactions)));
      this.averagePurchaseFormated = addSign('ap',Number(this.averagePurchase));
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }
};
